/* 分辨率125% */
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
    .menu {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 400px;
        height: 80px;
        color: black;
        font-weight: bold;
        margin-right: 200px;
    }

    .since {
        position: absolute;
        width: 40px;
        bottom: -130px;
        left: 60px;
    }

    .menu>div {
        cursor: pointer;
    }

    .menu>div:hover {
        font-size: 20px;
        color: #0075cf;
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        -moz-transition: all .3s linear;
        -ms-transition: all .3s linear;
        -o-transition: all .3s linear;
    }

    #head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 80px;
        background-color: rgba(226, 233, 252);
        position: sticky;
        top: 0px;
        z-index: 99999;
    }

    #head:hover {
        opacity: 1 !important;
    }

    .icon {
        width: 50px;
        height: 60px;
        margin-left: 200px;
    }

    .blockOne {
        background-image: url(../img/blockOneBg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 900px;
        margin-bottom: 5px;
        width: 100%;
        display: inline-block;
    }

    .blockOne-content {
        width: 600px;
        height: 300px;
        margin: 200px 0 0 130px;

    }

    .blockOne-font {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .blockOne-font-singel {
        color: #00599E;
    }

    .blockOne-describe {
        margin: 50px 0 150px 0;
    }

    .blockOne-foot {
        display: flex;
        font-size: 15px;
    }

    .blockOne-foot>img {
        width: 250px;
        margin-right: 10px;
    }

    .blockTwo {
        position: relative;
        height: 1600px;
        margin-bottom: 5px;
        overflow: hidden;
    }

    .blockTwo-head {
        width: 1200px;
        height: 400px;
        margin: 0 auto;
    }

    .blockTwo-font {
        font-size: 50px;
        margin: 50px 0;
    }

    .blockTwo-describe {
        margin-top: 50px;
    }

    .blockTwo-font>:nth-child(2) {
        font-size: 15px;
    }

    .blockTwo-imgOne {
        width: 400px;
        height: 900px;
        margin: 0 100px 0 200px;
    }

    .blockTwo-imgTwo {
        width: 500px;
        height: 400px;
    }

    .blockTwo-imgThree {
        width: 130px;
        position: absolute;
        top: 150px;
        right: 0;
    }

    .blockTwo-imgSix {
        width: 400px;
        height: 500px;
        position: absolute;
        bottom: 0;
    }

    .blockTwo-foot {
        background-image: url(../img/blockTwoBg3.png);
        position: absolute;
        bottom: 200px;
        right: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 400px;
        width: 900px;
        overflow: hidden;
    }

    .blockTwo-foot-descripe {
        margin: 180px 0 0 140px;
    }

    .blockThree {
        position: relative;
        height: 1100px;
        margin-bottom: 5px;
        background-color: #F4F8FF;
        overflow: hidden;

    }

    .blockThree-head {
        width: 1200px;
        height: 300px;
        margin: 0 auto;
    }

    .blockThree-font {
        font-size: 50px;
        margin: 50px 0;
    }

    .blockThree-font>:nth-child(2) {
        font-size: 15px;
    }

    .blockThree>img {
        display: block;
        margin: 0 auto;
        width: 1000px;
    }

    .blockFour {
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }

    .blockFour>img {
        width: 1200px;
    }

    .blockFive {
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F4F8FF;
        margin-bottom: 5px;
    }

    .blockFive>img {
        width: 1200px;
    }

    .blockSix {
        height: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }

    .blockSix>img {
        width: 1200px;
        height: 900px;
    }

    .blockSeven {
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F4F8FF;
        margin-bottom: 5px;
    }

    .blockSeven>img {
        width: 1200px;
    }

    .blockEight {
        height: 1100px;
        margin-bottom: 5px;
    }

    .blockEight-head {
        width: 1200px;
        height: 150px;
        margin: 0 auto;
    }

    .blockEight-font>:nth-child(2) {
        font-size: 15px;
    }

    .blockEight-font {
        font-size: 50px;
        margin: 50px 0;
    }

    .blockEight-foot {
        width: 1100px;
        margin: 0 auto;
        height: 800px;
    }

    .blockNine {
        height: 250px;
        display: flex;
        background-image: url(../img/blockNine1.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        color: #fff;
    }

    .blockNine-font {
        font-size: 30px;
        letter-spacing: 5px;
        margin-bottom: 20px;
    }

    .blockNine-btn {
        border-radius: 5px;
        border: #fff 1px solid;
        padding: 10px 15px;
        box-sizing: border-box;
        cursor: pointer;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
    }

    .blockNine-btn:hover {
        background-color: rgb(17, 142, 184);
    }

    .blockNine-btn:active {
        background-color: rgb(68, 62, 62);
    }

    .blockTen {
        height: 280px;
        color: #fff;
        background-color: #162027;
        margin-bottom: 5px;
        overflow: hidden;
    }

    .blockTen-content {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        height: 200px;
        margin: 40px auto 10px auto;
    }

    .blockTen-foot {
        display: block;
        color: #fff;
        text-decoration: none;
        width: 350px;
        text-align: center;
        margin: 0 auto;
    }

    .blockTen-foot:hover {
        color: #0091ff;
    }

    .blockTen-head {
        font-size: 15px;
    }

    .blockTen-head>img {
        width: 200px;
        margin-bottom: 20px;
    }

    .blockTen-main>img {
        width: 150px;

    }

    #goTop {
        font-size: 30px;
        text-align: center;
        color: #00599E;
        position: fixed;
        opacity: .7;
        z-index: 99999;
        right: 30px;
        bottom: 30px;
        width: 50px;
        height: 50px;
        background-color: #fff;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        cursor: pointer;
    }

    #goTop:hover {
        opacity: 1;
    }

}